import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header"
import Content from "../components/content"
import Footer from "../components/footer"
import SEO from "../components/seo"
import NewsPreview from '../components/news-preview'
import Img from "gatsby-image"

const HomePage = ({data}) => {
    const meta = data.graphAPI.homepage.meta
    const content = data.graphAPI.homepage.content
    return (
        <Layout>
            <SEO title={meta.title} />
            <div className="main">
                <Header />
                <div className="main-content-container">
                <div className="main-content">
                    {content[0].__typename !== 'GRAPHAPI_ComponentPageSplitContentImage' ? (
                        <div className="title-section">
                            <h1 className="title is-size-1">{meta.title}</h1>
                        </div>
                    ) : (<></>)}
                    <Content content={content} isHome={true} />
                    <div className="content-align">
                        <p><strong>Are you in the FBMUD 23 District? <a target="_blank" rel="noreferrer noopener" href="/assets/fbcmud023_subwidgetfull_20200813.jpg">Click here</a> to check the map!</strong></p>
                        <hr />
                        <h2 class="has-text-centered is-size-3" style={{fontWeight:600,marginBottom:'1rem'}}><em>Proudly serving the following sections:</em></h2>
                        <ul class="unstyled-list has-text-centered" style={{listStyle: 'none',columnCount:3,columnFill:'balance'}}>
                            <li>Andover Farms</li>
                            <li>Cambridge Falls</li>
                            <li>Estates of Teal Run</li>
                            <li>Teal Run</li>
                            <li>Teal Run North</li>
                            <li>Winfield Lakes</li>
                        </ul>
                    </div>


                    <div style={{marginTop: '8rem'}} className="news-container">
                        <NewsPreview posts={data.graphAPI.blogPosts} />
                    </div>
                    <div className="has-text-centered" style={{margin: '6rem 1rem'}}>
                        <p><strong>You've reached the end. <Link to="/archive">View News Archive</Link></strong></p>
                    </div>

                    
                </div>
                <Footer />
                </div>
            </div>
        </Layout>
    )
}

export default HomePage

export const homePageQuery = graphql`
    query homePage {
        useIt: file(relativePath: {eq: "water-use-it-wisely.png"}) {
            childImageSharp {
                fluid(fit: CONTAIN, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        twdb: file(relativePath: {eq: "TWDB_logo.png"}) {
            childImageSharp {
                fluid(fit: CONTAIN, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        wateriq: file(relativePath: {eq: "wateriq_logo.png"}) {
            childImageSharp {
                fluid(fit: CONTAIN, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        watersmart: file(relativePath: {eq: "logo_watersmart_20161218.png"}) {
            childImageSharp {
                fluid(fit: CONTAIN, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        epa: file(relativePath: {eq: "epa-logo.png"}) {
            childImageSharp {
                fluid(fit: CONTAIN, maxWidth: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        graphAPI {
            homepage {
                meta {
                    id
                    title
                    description
                }
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }
                }
            }
            blogPosts(limit: 6, sort: "publishedAt:desc") {
                id
                title
                description
                publishedAt
                featured
                expiresAt
                heroImage {
                    id
                    url
                    updated_at
                    documentFile {
                        childImageSharp {
                            fluid(maxWidth: 2048){
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
        } 
    }
`


