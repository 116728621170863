import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import moment from 'moment'

function slugify(text) {
  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
  const to = "aaaaaeeeeeiiiiooooouuuunc------"

  const newText = text.split('').map(
    (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

  return newText
    .toString()                     // Cast to string
    .toLowerCase()                  // Convert the string to lowercase letters
    .normalize('NFD')               // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim()                         // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/&/g, '-y-')           // Replace & with 'and'
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}


const NewsPreview = ({posts})=> {
    // Filter expired posts
    const activeposts = posts.filter(post => {
        if(post.expiresAt) {
            return moment(post.expiresAt).isAfter(moment())
        } else {
            return true
        }
    })
    const featuredPosts = activeposts.filter(post => {
        if(post.featured) {
            return true
        } else {
            return false
        }
    })
    const nonFeaturedPosts = activeposts.filter(post => {
        if(post.featured) {
            return false
        } else {
            return true
        }
    })
    const postList = featuredPosts.concat(nonFeaturedPosts)
    return (
        <div className="news-preview">
            {postList.map(post => {
                const date = moment(post.publishedAt)
                const endslug = slugify(post.title)
                const slug = `/posts/${date.format("YYYY-MM-DD")}/${endslug}`
                return (
                    <Link to={slug} className="news-card">
                        <div className="news-image">
                            <Img className="image" fluid={post.heroImage.documentFile.childImageSharp.fluid} />
                        </div>
                        <div className="news-card-body">
                            <small>{date.format("MMM D, YYYY")}</small>
                            <h3 className="title is-size-4">{post.title}</h3>
                            <p className="">{post.description}</p>

                        </div>
                        <div className="news-card-footer">
                            <Link to={slug} className="button is-primary">READ MORE</Link>
                        </div>
                    </Link>

                )})}
            

        </div>

    )
}

export default NewsPreview